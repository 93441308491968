import React from "react";
import { HorizontalScrollable } from "components/Shared";
import { ourServices } from "./data/our-services";
import * as S from "./styles";
import Card from "./Card";

const OurServices: React.FC = () => {
  return (
    <S.Container>
      <S.TextWrapper>
        <S.Title>O que fazemos</S.Title>
        <S.Text>
          Protegemos sua marca, seus inventos, sua arte e outros ativos
          intelectuais da sua empresa. Confira abaixo.
        </S.Text>
      </S.TextWrapper>
      <HorizontalScrollable snapAlign="none" enableHandling>
        {ourServices.map((service) => (
          <Card key={service.id} ourService={service} />
        ))}
      </HorizontalScrollable>
    </S.Container>
  );
};

export default OurServices;
