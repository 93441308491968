import React from "react";
import { Switch, Route } from "react-router-dom";
import Home from "pages/Home";
import WhoWeAre from "pages/WhoWeAre";
import Blog from "pages/Blog";
import Post from "pages/Blog/Post";

export const Routes: React.FC = () => {
  return (
    <Switch>
      <Route path="/" exact component={Home} />
      <Route path="/home/:slug" exact component={Home} />
      <Route path="/quem-somos" exact component={WhoWeAre} />
      <Route path="/blog" exact component={Blog} />
      <Route path="/blog/:id/:slug" exact component={Post} />
    </Switch>
  );
};
