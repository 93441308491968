import styled from "styled-components";
import { Whatsapp } from "@styled-icons/boxicons-logos";
import { colors, fonts } from "styles";

export const Container = styled.div`
  width: 100%;
  background-color: ${colors.dark};
  padding: 40px 32px;
  position: sticky;
  top: 80px;
`;

export const IconWhats = styled(Whatsapp).attrs({ size: 48 })`
  color: #fff;
  margin-bottom: 40px;
`;

export const Title = styled.h4`
  font-family: ${fonts.GilroySemiBold};
  font-size: 28px;
  line-height: 32px;
  color: ${colors.cyan};
  margin-bottom: 24px;
`;

export const Text = styled.article`
  font-family: ${fonts.OpenSans};
  font-size: 14px;
  line-height: 20px;
  color: #fff;
  margin-bottom: 64px;
`;

export const Button = styled.button.attrs({ type: "button" })`
  width: 100%;
  padding: 16px 32px;
  background-color: #fff;
  font-family: ${fonts.GilroySemiBold};
  font-size: 16px;
  color: ${colors.dark};
  transition: 300ms ease;

  &:hover {
    transform: scale(1.1);
  }
`;
