import styled from "styled-components";
import { colors } from "styles";

export const Button = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  background-color: ${colors.dark};
  color: #fff;
  font-size: 18px;
  padding: 16px 24px;
  transition: 300ms ease;

  &:hover {
    transform: scale(1.1);
  }
`;
