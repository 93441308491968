import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { InstagramAlt, Linkedin, Facebook } from '@styled-icons/boxicons-logos';
import { ReactComponent as Symbol } from 'assets/images/logo-symbol.svg';
import { colors, fonts } from 'styles';

export const Container = styled.footer`
  width: 100%;
  background-color: ${colors.lightGrey};
  padding: 64px 0;
  border-bottom: 8px solid ${colors.dark};
`;

export const Content = styled.div`
  display: flex;
  column-gap: 127px;
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  padding-bottom: 56px;
  border-bottom: 1px solid ${colors.dark};
  margin-bottom: 32px;

  @media screen and (max-width: 1100px) {
    padding-left: 24px;
    padding-right: 24px;
    column-gap: 80px;
  }

  @media screen and (max-width: 768px) {
    width: max-content;
    flex-direction: column;
    row-gap: 24px;
    padding-left: 0;
    padding-right: 0;
  }
`;

export const Column = styled.div``;

export const Logo = styled(Symbol)`
  margin-bottom: 24px;
`;

export const Text = styled.article`
  width: 100%;
  max-width: 345px;
  font-size: 16px;
  line-height: 24px;
  color: ${colors.dark};

  p {
    margin-bottom: 24px;
    &.address {
      font-size: 12px;
    }
  }

  a {
    color: ${colors.dark};
    text-decoration: underline;
  }
`;

export const Title = styled.h4`
  font-size: 24px;
  margin-bottom: 24px;
`;

export const Option = styled(Link)`
  font-family: ${fonts.GilroyBold};
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${colors.dark};

  display: flex;
  align-items: center;
  width: max-content;
  margin-bottom: 16px;
  position: relative;

  &:after {
    content: '';
    display: block;
    width: 0;
    height: 4px;
    border-radius: 2px;
    background-color: ${colors.dark};
    position: absolute;
    bottom: -8px;
    transition: 300ms ease;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
`;

export const SocialNetwork = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;

  a {
    text-decoration: none;
  }
  svg {
    color: ${colors.dark};
  }
`;

export const IconInstagram = styled(InstagramAlt).attrs({ size: 20 })``;
export const IconLinkedin = styled(Linkedin).attrs({ size: 20 })``;
export const IconFacebook = styled(Facebook).attrs({ size: 20 })``;

export const Foco = styled.a`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: ${fonts.GilroySemiBold};
  font-size: 14px;
  color: ${colors.dark};
  text-decoration: none;
  text-align: center;
`;
