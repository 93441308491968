import styled from "styled-components";

export const Container = styled.main`
  width: 100%;
  margin: 0 auto;
`;

export const Content = styled.div`
  width: 100%;

  @media screen and (max-width: 768px) {
    padding: 0 24px;
  }
`;
