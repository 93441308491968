import React from "react";

import * as S from "./styles";

const Spotlight: React.FC = () => {
  return (
    <S.Container>
      <S.TextWrapper>
        <S.Title>
          Proteção para <span>sua marca</span> e criações com estratégia e
          segurança.
        </S.Title>
        <S.Text>
          Somos um escritório totalmente digital, com atendimento personalizado
          e atenção especial aos pequenos, microempresários e profissionais
          liberais.
        </S.Text>
      </S.TextWrapper>
      <S.ImagesWrapper>
        <S.ImageWrapper>
          <S.SpotlightImageFirst />
        </S.ImageWrapper>
        <S.ImageWrapper>
          <S.SpotlightImageLast />
        </S.ImageWrapper>
      </S.ImagesWrapper>
    </S.Container>
  );
};

export default Spotlight;
