import React, { useEffect } from 'react';
import Reactga from 'react-ga';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import { PersistGate } from 'redux-persist/integration/react';

import { Whatsapp } from 'components/Shared';
import { Routes } from 'routes';
import { ScrollFixer } from 'utils/ScrollFixer';

import { persistor, store } from './store';
import GlobalStyle from './styles/global';

// import "./config/ReactotronConfig";

toast.configure();

const App: React.FC = () => {
  useEffect(() => {
    Reactga.initialize('UA-214855432-1');
    Reactga.pageview(window.location.pathname);
  }, []);
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <BrowserRouter basename="/">
          <ScrollFixer />
          <GlobalStyle />
          <Whatsapp />
          <Routes />
        </BrowserRouter>
      </PersistGate>
    </Provider>
  );
};

export default App;
