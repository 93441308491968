import React from 'react';
import * as S from './styles';

const Footer: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Column>
          <S.Logo />
          <S.Text>
            <p>
              Protegendo suas criações, prevenindo riscos e gerando valor para o
              seu negócio desde 2020.
            </p>
            <p className="address">
              Rua Abdo Felipe, 202, Sala 814 - Ano Bom Center, Ano Bom, Barra
              Mansa, CEP 27323-000
            </p>
            <a
              href="/docs/Termos-Politica-de-Dados-e-Privacidade-França-e-Leon.pdf"
              target="_blank"
              rel="noopener noreferrer"
            >
              Política de privacidade
            </a>
          </S.Text>
        </S.Column>
        <S.Column>
          <S.Title>Acesso rápido</S.Title>
          <S.Option to="/">Home</S.Option>
          <S.Option to="/quem-somos">Quem somos</S.Option>
          <S.Option to="/home/servicos">O que fazemos</S.Option>
          <S.Option to="/blog">Blog</S.Option>
          <S.Option to="/home/contato">Fale com a gente</S.Option>
        </S.Column>
        <S.Column>
          <S.Title>Siga-nos</S.Title>
          <S.SocialNetwork>
            <a
              href="https://instagram.com/francaeleon/"
              target="_blank"
              rel="noreferrer"
            >
              <S.IconInstagram />
            </a>
          </S.SocialNetwork>
        </S.Column>
      </S.Content>
      <S.Foco href="https://fococomunicacao.com/" target="_blank">
        Desenvolvido por Foco Comunicação ®
      </S.Foco>
    </S.Container>
  );
};

export default Footer;
