import React, { useCallback, useEffect } from "react";
import { Element, scroller } from "react-scroll";
import { useParams } from "react-router-dom";
import { Scaffold, Contact } from "components/Shared";
import Spotlight from "./Spotlight";
import OurServices from "./OurServices";
import LastPosts from "./LastPosts";
import * as S from "./styles";

interface ParamTypes {
  slug: string;
}
const Home: React.FC = () => {
  const { slug } = useParams<ParamTypes>();
  const handleMenu = useCallback((element: string) => {
    scroller.scrollTo(element, {
      duration: 400,
      delay: 0,
      offset: -90,
    });
  }, []);

  useEffect(() => {
    handleMenu(slug);
  }, [handleMenu, slug]);
  return (
    <Scaffold>
      <S.Container>
        <S.Content>
          <Spotlight />
          <Element name="servicos" />
          <OurServices />
        </S.Content>
        <LastPosts />
        <S.Content>
          <Element name="contato" />
          <Contact />
        </S.Content>
      </S.Container>
    </Scaffold>
  );
};

export default Home;
