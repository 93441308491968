import styled from "styled-components";
import livia from "assets/images/livia-nova.jpg";
import thiago from "assets/images/thiago-nova.jpg";
import lais from "assets/images/lais.jpg";
import rachel from "assets/images/rachel.jpg";
import { colors, fonts } from "styles";

export const Container = styled.section`
  width: 100%;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 88px;
  width: 100%;

  @media screen and (max-width: 980px) {
    flex-direction: column;
    max-width: 532px;

    &.right {
      flex-direction: column-reverse;
    }
  }

  @media screen and (max-width: 414px) {
    margin-bottom: 32px;
  }
  @media screen and (max-width: 375px) {
    margin-bottom: 24px;
  }
`;

export const Content = styled.div`
  width: 50%;

  &.info {
    padding: 48px 72px;
  }

  @media screen and (max-width: 980px) {
    width: 100%;
  }
  @media screen and (max-width: 414px) {
    &.info {
      padding: 48px 0;
    }
  }
`;

export const Name = styled.h2`
  font-family: ${fonts.GilroySemiBold};
  font-size: 36px;
  line-height: 36px;
  color: ${colors.dark};
  margin-bottom: 24px;

  @media screen and (max-width: 414px) {
    font-size: 28px;
    line-height: 28px;
  }
  @media screen and (max-width: 320px) {
    font-size: 22px;
    line-height: 22px;
  }
`;

export const Text = styled.article`
  width: 100%;
  font-family: ${fonts.OpenSans};
  font-size: 16px;
  line-height: 24px;
  color: ${colors.dark};

  p {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Livia = styled.img.attrs({ src: livia })`
  width: 100%;
  max-width: max-content;
  object-fit: contain;
`;

export const Thiago = styled.img.attrs({ src: thiago })`
  width: 100%;
  max-width: max-content;
  object-fit: contain;
`;

export const Lais = styled.img.attrs({ src: lais })`
  width: 100%;
  max-width: max-content;
  object-fit: contain;
`;

export const Rachel = styled.img.attrs({ src: rachel })`
  width: 100%;
  max-width: max-content;
  object-fit: contain;
`;
