import React from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { PaginatedArticle } from 'contracts/Articles';
import { toSlug } from 'utils';
import imageDefault from 'assets/images/default.jpg';
import * as S from './styles';

interface CardProps {
  article: PaginatedArticle;
}
const Card: React.FC<CardProps> = ({ article }) => {
  return (
    <S.Container to={`/blog/${article.id}/${toSlug(article.title)}`}>
      <S.ImageWrapper>
        <S.Tag>{article.category.name}</S.Tag>
        {article.image_url ? (
          <img src={article.image_url} alt={article.title} />
        ) : (
          <img src={imageDefault} alt={article.title} />
        )}
      </S.ImageWrapper>
      <S.Title>{article.title}</S.Title>
      <S.PostDate>
        {format(new Date(article.updated_at), "dd 'de' MMMM yyyy", {
          locale: ptBR,
        })}
      </S.PostDate>
    </S.Container>
  );
};

export default Card;
