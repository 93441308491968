import React from 'react';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import { PaginatedArticle } from 'contracts/Articles';
import { toSlug } from 'utils';
import * as S from './styles';

interface PreviewProps {
  article: PaginatedArticle;
}
const Preview: React.FC<PreviewProps> = ({ article }) => {
  return (
    <S.Container to={`/blog/${article.id}/${toSlug(article.title)}`}>
      <S.ImageWrapper>
        {article.category && <S.Tag>{article.category.name}</S.Tag>}
        {article.image_url && (
          <img src={article.image_url} alt={article.title} />
        )}
      </S.ImageWrapper>
      <S.Title>{article.title}</S.Title>
      <S.ContentExerpt
        dangerouslySetInnerHTML={{ __html: article.content_exerpt }}
      />
      <S.PostDate>
        {format(new Date(article.updated_at), "dd 'de' MMMM yyyy", {
          locale: ptBR,
        })}
      </S.PostDate>
    </S.Container>
  );
};

export default Preview;
