import React from "react";
import { IOurService } from "../data/our-services";

import * as S from "./styles";

interface ICard {
  ourService: IOurService;
}
const Card: React.FC<ICard> = ({
  ourService: { icon, title, description },
}) => {
  return (
    <S.Container>
      <img src={icon} alt="title" />
      <S.Title>{title}</S.Title>
      <S.Description dangerouslySetInnerHTML={{ __html: description }} />
    </S.Container>
  );
};

export default Card;
