import styled from "styled-components";
import { colors } from "styles";
import { Loading } from "styles/styled-components";

export const Container = styled.section`
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 96px 24px;
  background-color: ${colors.dark};
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
  display: flex;
  column-gap: 40px;

  @media screen and (max-width: 1000px) {
    max-width: max-content;
    flex-direction: column;
    row-gap: 40px;
  }
`;

export const ActivityIndicator = styled(Loading)`
  color: #fff;
`;
