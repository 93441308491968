import React, { useCallback } from "react";

import { Pagination } from "contracts/Pagination";
import * as S from "./styles";

type Props = {
  pagination?: Pagination;
  onPageChange?: (page: number) => void;
  neighbors?: number;
};

export const Paginator: React.FC<Props> = ({
  pagination,
  onPageChange,
  neighbors = 2,
}) => {
  const handleClick = useCallback(
    (page: number): void => {
      onPageChange && onPageChange(page);
    },
    [onPageChange]
  );

  const ButtonsPresenter = useCallback((): JSX.Element => {
    if (!pagination) return <></>;

    const { current_page, last_page } = pagination;
    if (last_page <= 1) return <></>;

    const targetSize = neighbors * 2;
    const middle = Math.floor(targetSize / 2);
    const paginatorArray = [];

    let start = current_page > middle ? current_page - middle : 1;
    start = start > last_page - targetSize ? last_page - targetSize : start;
    start = start < 1 ? 1 : start;
    let t = start + targetSize;
    t = last_page < t ? last_page : t;
    for (let i = start; i <= t; i++) {
      paginatorArray.push(i);
    }

    return (
      <S.Container>
        {paginatorArray.map((i) => (
          <S.Button
            key={i}
            active={current_page === i}
            onClick={() => handleClick(i)}
          >
            {i}
          </S.Button>
        ))}
      </S.Container>
    );
  }, [handleClick, neighbors, pagination]);

  return <ButtonsPresenter />;
};
