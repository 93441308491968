import React from "react";
import Header from "./Header";
import Footer from "./Footer";

import * as S from "./styles";

export const Scaffold: React.FC = ({ children }) => {
  return (
    <S.Container>
      <Header />
      <S.Content>{children}</S.Content>
      <Footer />
    </S.Container>
  );
};
