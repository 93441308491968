import { all, takeLatest } from "redux-saga/effects";

// Types
import { FetchArticleTypes, PaginateArticlesTypes } from "store/ducks/articles";

// Sagas
import { fetchArticleRequest, paginateArticlesRequest } from "./articles";

export default function* rootSaga() {
  yield all([
    // articles
    takeLatest(FetchArticleTypes.REQUEST, fetchArticleRequest),
    takeLatest(PaginateArticlesTypes.REQUEST, paginateArticlesRequest),
  ]);
}
