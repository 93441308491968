import styled from "styled-components";
import { Link } from "react-router-dom";
import { InstagramAlt, Linkedin, Facebook } from "@styled-icons/boxicons-logos";
import { ReactComponent as logo } from "assets/images/logo.svg";
import { colors, fonts } from "styles";

export const Container = styled.header`
  border-top: 8px solid ${colors.dark};
  margin: 0 auto;
  width: 100%;
  background-color: #fff;
  padding: 16px 24px;
  position: sticky;
  top: 0;
  z-index: 98;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1100px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 auto;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 24px;
  }

  @media screen and (max-width: 500px) {
    flex-direction: row;
  }
`;

export const Logo = styled(logo)``;

export const Navigation = styled.nav`
  display: flex;
  align-items: center;
  column-gap: 40px;

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const Option = styled(Link)`
  font-family: ${fonts.GilroyBold};
  font-size: 14px;
  line-height: 20px;
  text-transform: uppercase;
  color: ${colors.dark};

  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &:after {
    content: "";
    display: block;
    width: 0;
    height: 4px;
    border-radius: 2px;
    background-color: ${colors.dark};
    position: absolute;
    bottom: -8px;
    transition: 300ms ease;
  }

  &:hover {
    &:after {
      width: 100%;
    }
  }
`;

export const SocialNetwork = styled.div`
  display: flex;
  align-items: center;
  column-gap: 16px;

  svg {
    color: ${colors.dark};
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const IconInstagram = styled(InstagramAlt).attrs({ size: 20 })``;
export const IconLinkedin = styled(Linkedin).attrs({ size: 20 })``;
export const IconFacebook = styled(Facebook).attrs({ size: 20 })``;
