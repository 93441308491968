import styled from "styled-components";
import { TitleH2 } from "styles/styled-components";
import { colors } from "styles";

export { FormRow, Button } from "styles/styled-components";

export const Container = styled.section`
  width: 100%;
  padding: 96px 0;
`;

export const Content = styled.div`
  width: 100%;
  max-width: 1100px;
  margin: 0 auto;
`;

export const TextWrapper = styled.div`
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

export const Title = styled(TitleH2)``;

export const Text = styled.article`
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.dark};
  margin-bottom: 64px;
`;

export const FormContainer = styled.div`
  width: 100%;
  max-width: 590px;
  margin: 0 auto;
`;

export const FormFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 32px;
`;
