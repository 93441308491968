import { Paginator } from 'components/Shared';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, RootStateOrAny } from 'react-redux';
import {
  PaginateArticlesState,
  PaginateArticlesActions,
} from 'store/ducks/articles';
import Preview from './Preview';

import * as S from './styles';

const PaginateArticles: React.FC = () => {
  const dispatch = useDispatch();
  const [query, setQuery] = useState({
    search: '',
    page: 1,
    limit: 8,
  });
  const {
    data: articles,
    loading,
    pagination,
  } = useSelector<RootStateOrAny, PaginateArticlesState>(
    (state) => state.paginateArticles
  );

  const handlePageChange = useCallback((page: number): void => {
    setQuery((state) => ({ ...state, page }));
  }, []);

  const getLastArticles = useCallback(() => {
    dispatch(PaginateArticlesActions.request(query));
  }, [dispatch, query]);

  useEffect(() => {
    getLastArticles();
  }, [getLastArticles]);

  useEffect(() => {
    return () => {
      dispatch(PaginateArticlesActions.reset());
    };
  }, [dispatch]);
  return (
    <S.Container>
      {loading && <S.Loading />}
      {articles.map((article) => (
        <Preview key={article.id} article={article} />
      ))}
      <Paginator onPageChange={handlePageChange} pagination={pagination} />
    </S.Container>
  );
};

export default PaginateArticles;
