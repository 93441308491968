import React from 'react';
import NavigationMobile from './NavigationMobile';

import * as S from './styles';

const Header: React.FC = () => {
  return (
    <S.Container>
      <S.Content>
        <S.Logo />
        <NavigationMobile />
        <S.Navigation>
          <S.Option to="/">Home</S.Option>
          <S.Option to="/quem-somos">Quem somos</S.Option>
          <S.Option to="/home/servicos">O que fazemos</S.Option>
          <S.Option to="/blog">Blog</S.Option>
          <S.Option to="/home/contato">Fale com a gente</S.Option>
        </S.Navigation>
        <S.SocialNetwork>
          <a
            href="https://instagram.com/francaeleon/"
            target="_blank"
            rel="noreferrer"
          >
            <S.IconInstagram />
          </a>
          {/* <Link to="/">
            <S.IconLinkedin />
          </Link>
          <Link to="/">
            <S.IconFacebook />
          </Link> */}
        </S.SocialNetwork>
      </S.Content>
    </S.Container>
  );
};

export default Header;
