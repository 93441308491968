import React from 'react';
import * as S from './styles';

const Intellectual: React.FC = () => {
  return (
    <S.Container>
      <S.Title>Propriedade Intelectual</S.Title>
      <S.Text>
        <p>
          A Propriedade Intelectual está relacionada com a proteção do
          conhecimento produzido, podendo o autor, por determinado período,
          explorar economicamente suas invenções, marcas, desenhos ou criações
          artísticas, por exemplo.
        </p>
        <p>
          Em um mundo cada vez mais digital, esses ativos intelectuais assumem
          total importância. E é só prestar atenção a sua volta que isso fica
          evidente: eles estão nas marcas que você escolhe para comprar, nos
          filmes que você assiste, nos aplicativos de celular que você usa, ou
          seja, praticamente em todos os momentos da sua vida.{' '}
        </p>
        <p>
          A preocupação com a propriedade intelectual não envolve proteger
          apenas a invenção em si, mas também todo o investimento realizado para
          materializar a ideia. Além disso, previne o risco de eventual violação
          de direito alheio, evitando o custo com indenizações.{' '}
        </p>
        <p>
          Por isso, sua marca, suas criações e seus inventos merecem a proteção
          adequada e estamos aqui para ajudá-lo nessa missão e colaborar com o
          sucesso do seu negócio.
        </p>
      </S.Text>
    </S.Container>
  );
};

export default Intellectual;
