import { createGlobalStyle } from "styled-components";

import GilroyLight from "assets/fonts/Gilroy-Light.otf";
import GilroySemiBold from "assets/fonts/Gilroy-SemiBold.otf";
import GilroyBold from "assets/fonts/Gilroy-Bold.otf";

const GlobalStyle = createGlobalStyle`

  @font-face {
    font-family: "GilroyLight";
    src: url(${GilroyLight});
  }

  @font-face {
    font-family: "GilroySemiBold";
    src: url(${GilroySemiBold});
  }

  @font-face {
    font-family: "GilroyBold";
    src: url(${GilroyBold});
  }

  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    outline: none;
  }

  html,
  body,
  #root {
    height: 100%;
    scroll-behavior: smooth;
  }

  body {
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    background-color: #ffffff;
    font-family: "Open Sans", sans-serif;;
    font-size: 16px;
    color: #2B2B2B;
    font-weight: normal;
  }

  a {
    text-decoration: none;
  }

  button {
    font-family: "GilroyBold";
    border: 0;
    cursor: pointer;
    background-color: transparent;
  }

  input, textarea {
    font-family: "Open Sans", sans-serif;
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: "GilroyBold";
    font-weight: unset;
  }

  ul {
    list-style: none;
  }

  .icon-spin {
    animation: iconSpin 2s infinite linear;
  }

  @keyframes iconSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(359deg);
    }
  }
`;

export default GlobalStyle;
