import React from 'react';
import * as S from './styles';

export const Whatsapp: React.FC = () => {
  return (
    <S.Button onClick={() => window.open('https://wa.me/5524998318777')}>
      <S.Icon />
    </S.Button>
  );
};
