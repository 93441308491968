import styled from "styled-components";
import office from "assets/images/office.jpg";
import { colors, fonts } from "styles";

export const Container = styled.section`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 88px;
`;

export const Title = styled.h2`
  font-family: ${fonts.GilroySemiBold};
  font-size: 40px;
  text-align: center;
  color: ${colors.dark};
  margin-bottom: 40px;

  @media screen and (max-width: 414px) {
    font-size: 32px;
  }
  @media screen and (max-width: 375px) {
    font-size: 28px;
  }
`;

export const Text = styled.article`
  width: 100%;
  max-width: 575px;
  font-family: ${fonts.OpenSans};
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: ${colors.dark};
  margin-bottom: 64px;

  p {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
`;

export const Wrapper = styled.div`
  display: flex;
  width: max-content;
  column-gap: 24px;
  margin-bottom: 88px;

  @media screen and (max-width: 768px) {
    flex-direction: column;
    row-gap: 24px;
  }
`;

export const ValuesAndObjectives = styled.div`
  width: 240px;
  font-family: ${fonts.OpenSans};
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: ${colors.dark};
`;

export const BackgroudOffice = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 32px;
  background-color: ${colors.dark};

  @media screen and (max-width: 414px) {
    padding: 0;
  }
`;

export const ImageOffice = styled.img.attrs({ src: office })`
  object-fit: contain;
  width: 100%;
  max-width: max-content;
`;
