import React from "react";

import * as S from "./styles";

const AboutUs: React.FC = () => {
  return (
    <S.Container>
      <S.Title>Protegendo você</S.Title>
      <S.Text>
        <p>
          Somos um escritório totalmente digital, com atendimento personalizado
          e atenção especial aos pequenos e microempresarios e profissionais
          liberais que também precisaram migrar para o digital em razão da
          pandemia.
        </p>

        <p>
          Dessa digitalização da vida podem surgir conflitos que por vezes se
          mostram difíceis de superar, mas um olhar especializado sobre o mundo
          digital pode prevenir tais conflitos ou facilitar a busca pelas
          soluções mais adequadas quando os problemas já surgiram.
        </p>
      </S.Text>
      <S.Title>Valores e objetivos</S.Title>
      <S.Wrapper>
        <S.ValuesAndObjectives>
          Advocacia ética e voltada para soluções considerando a realidade
          judicial e legislativa.
        </S.ValuesAndObjectives>
        <S.ValuesAndObjectives>
          Preocupação com o custo e com a solução eficiente dos conflitos.
        </S.ValuesAndObjectives>
        <S.ValuesAndObjectives>
          Transparência sobre a situação em questão e sobre o processo.
        </S.ValuesAndObjectives>
      </S.Wrapper>
      <S.BackgroudOffice>
        <S.ImageOffice />
      </S.BackgroudOffice>
    </S.Container>
  );
};

export default AboutUs;
